import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser';
import Breadcump from '../../components/Breadcump'
import InputSelectDinamic from '../../components/InputSelectDynamic'
import { GET_SHEDUlES } from '../../queries/pages'
import { client } from '../../Services/wordpress'
import { formattedDate } from '../../utils/formattedDateShedule'
import { Form } from "@unform/web";
import { FiAlertTriangle } from 'react-icons/fi'

import { Helmet } from "react-helmet";

import { Container, Modal, MaintenanceContainer, ModalSheduleContent } from './styles'
import SocialShare from '../../components/SocialShare'
import { useLanguage } from '../../hooks/useLanguage'
import { Link } from 'react-router-dom'

const Shedule = () => {
  const { language } = useLanguage()

  const formRef = useRef(null)
  const [originalData, setOriginalData] = useState([])
  const [data, setData] = useState([])
  const [dateShedule, setDateShedule] = useState('')
  const [currentShedules, setCurrentShedules] = useState([])
  const [themeSelects, setThemeSelects] = useState([])
  const [filterRoom, setFilterRoom] = useState('')
  const [filterTheme, setFilterTheme] = useState('')
  const [filterTime, setFilterTime] = useState('')
  const [toggle, setToggle] = useState(false)
  const [toggleModal, setToggleModal] = useState(false)
  const [sheduleSelect, setSheduleSelect] = useState({})

  const ordenSchedule = (a, b) => {

    if(a?.start && b?.start){
      const timeA = a.start?.split(":"); // dividir a string em horas e minutos
      const timeB = b.start?.split(":");
      
      const hourA = parseInt(timeA[0]);
      const minuteA = parseInt(timeA[1]);
      const hourB = parseInt(timeB[0]);
      const minuteB = parseInt(timeB[1]);
      
          
      if (hourA === hourB) {
        return minuteA - minuteB; // comparar minutos se as horas forem iguais
      } else {
        return hourA - hourB; // comparar horas
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    setCurrentShedules(
      _ => data.find(obj => obj.date === dateShedule)?.posts || data[0]?.posts || []
    )
  }, [dateShedule, data])


  useEffect(() => {
    if (data) {
      console.log("data temes ", data)
      const themes = data.map(obj => obj.posts
        .map(post => ({ id: post.theme.id, name: post.theme.name })))
        .flat(Infinity)
        .filter((obj, position, array) => {
          if (array.findIndex(el => el.name === obj.name) === position) {
            return obj
          }
          return false
        })
        .sort((a, b) => a.name - b.name)
      setThemeSelects(themes)
    }
  }, [data])

  useEffect(() => {
    const body = document.querySelector('body')

    if (toggle || toggleModal) {
      if (body) {
        body.style.overflow = 'hidden'
        body.style.height = '100vh'
      }
    } else if (body) {
      body.style.overflow = ''
      body.style.height = ''
    }
  }, [toggle, toggleModal])

  async function getData() {
    const res = await client.query({
      query: GET_SHEDUlES
    })

    const { data: requestData } = res
    if (requestData) {
      const newData = requestData.programacoesDatasPalestras.nodes.map(date => {
        return ({
          id: date.id,
          date: date.name,
          dateFormatted: formattedDate(date.name),
          dateFormattedEN: formattedDate(date.name, 'en'),
          posts: date.programacoes.nodes.map(post => {
            return ({
              id: post.id,
              title: post.title,
              subTitle: post.acfprogramacao.subTitulo,
              dateLecture: post.acfprogramacao.datePalestra,
              dateLectureFormatted: formattedDate(post.acfprogramacao.datePalestra),
              dateLectureFormattedEN: formattedDate(post.acfprogramacao.datePalestra, 'en'),
              spotlight: post.acfprogramacao.destaqueDaPalestra?.length > 0 || false,
              start: post.acfprogramacao.horarioDeInicio,
              end: post.acfprogramacao.horarioTermino,
              room: {
                id: (() => {
                  try {
                    return post.acfprogramacao.localPalestra[0]
                  } catch (e) {
                    return null
                  }
                })(),
                name: (() => {
                  try {
                    return post.acfprogramacao.localPalestra[1]
                  } catch (e) {
                    return null
                  }
                })()
              },
              excerpt: post.acfprogramacao.resumoDaPalestra,
              initials: post.acfprogramacao.sigla,
              theme: {
                id: (() => {
                  try {
                    console.log( post.acfprogramacao.temas[0])
                    return post.acfprogramacao.temas[0]
                  } catch (e) {
                    return null
                  }
                })(),
                name: (() => {
                  try {
                    console.log( post.acfprogramacao.temas[1])
                    return post.acfprogramacao.temas[1]
                  } catch (e) {
                    return null
                  }
                })(),
              },
              speakers: post.acfprogramacao.cadastroPalestrante?.map((el, index) => {
                return ({
                  id: index.toString(),
                  name: el.nome,
                  company: el.empresa,
                })
              }) || [],
              translations: post.acfIdioma.idioma,
            })
          })
        })
      })
      const reduceData = newData.map(day => {
        const newposts = day.posts.reduce((acc, cur) => {
          let controle = []
          
          // append no array controle caso esteja na primeira iteração
          if(acc.length === 0) {
            controle = [...acc, cur]
            return controle
          }

          // obtendo index de palestras que acontecem na mesma hora de inicio
          const index = acc.findIndex((obj) => obj.start === cur.start && obj.end === cur.end && obj.translations === cur.translations)

          // append no array controle caso não tenha nenhuma palestra que aconteça no mesmo horario
          if(index === -1) {
            controle = [...acc, cur]
            return controle
          }
  
          // adiciona elemento atual da iteração no array group daquele horario
          if(acc[index].group) {
            acc[index].group.push(cur)
            controle = [...acc]
            return controle
          }

          // cria array group para o horario caso não exista
          const obj = { ...acc[index] }
          acc[index].group = [obj, cur]
          controle = [...acc]
          return controle
        }, [])

        return { ...day, posts: newposts }
      })
      reduceData.map(day => {
        const groupSorted = day.posts.map(post => {
          if(post.group) {
            post.group.sort((a, b) => {
              const nameA = a.room.name?.split(' | ')[0]?.toLowerCase() 
              const nameB = b.room.name?.split(' | ')[0]?.toLowerCase()
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0
            })
            return post
          }
        })

        return { ...day, posts: groupSorted }
      })
      setOriginalData(newData)
      setData(reduceData)
      setDateShedule(newData[0].date || '')
    } else {
      setData([])
    }
  }

  function handleSubmitFiltres(data) {
    const { room, theme, time } = data
    setFilterRoom(room)
    setFilterTheme(theme)
    setFilterTime(time)
  }
  function handleFilterRoom(shedule) {
    if (filterRoom) {
      if (shedule?.room?.name === filterRoom) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  function handleFilterTheme(shedule) {
    if (filterTheme) {
      if (shedule.theme.name === filterTheme) {
        return true
      } else {
        return false
      }
    }
    return true
  }
  function handleFilterTime(shedule) {
    if (filterTime) {
      const sheduleTime = shedule.start?.split(":")[0] >= 12
        ? "Tarde"
        : "Manhã"
      if (sheduleTime === filterTime) {
        return true
      } else {
        return false
      }
    }
    return true
  }
  function clearFilters() {
    setFilterRoom('')
    setFilterTheme('')
    setFilterTime('')
  }

  function handleModal(id) {
    const shedule = originalData
      .map(obj => obj.posts)
      .flat(Infinity)
      .find(post => post.id === id)

    if (shedule) {
      setSheduleSelect(shedule)
      setToggleModal(true)
    }
    return
  }

  /*return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? 'Schedule - Rio Pipeline'
            : 'Programação 2023 - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/schedule" />
          : <link rel="canonical" href="/programacao" />
        }
      </Helmet>
      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'schedule', name: 'Schedule' }]
          : [{ link: 'programacao', name: 'Programação 2023' }]
      } />
      
      <Container>
        <MaintenanceContainer>
          <FiAlertTriangle size={80} />
          <h2>Página em manutenção</h2>
          <a href="https://painel.riopipeline.com.br/wp-content/uploads/2023/06/Agenda-Rio-Pipeline-2023.xlsx">
            Enquanto isso, baixe aqui a Programação 2023 do evento
          </a>
        </MaintenanceContainer>
      </Container>
    </Fragment>
  )*/

  return (
    <Fragment>
      <Helmet>
        <title>
          {language === 'en_US'
            ? '2023 Conference Agenda - Rio Pipeline'
            : 'Programação 2023 - Rio Pipeline'
          }
        </title>

        {language === 'en_US'
          ? <link rel="canonical" href="/schedule" />
          : <link rel="canonical" href="/programacao" />
        }
      </Helmet>
      <Breadcump customPath={
        language === 'en_US'
          ? [{ link: 'schedule', name: '2023 Conference Agenda' }]
          : [{ link: 'programacao', name: 'Programação 2023' }]
      } />
      
      <Container>
        <header>
          <h1>{language === "en_US" ? "2023 Conference Agenda" : "Programação 2023"}</h1>

          <div className="filter-mobile">
            <button type="button" disabled={!data.length > 0} onClick={() => setToggle(!toggle)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path d="M15.249,0H.751a.751.751,0,0,0-.53,1.28L6,7.061V13.5a.75.75,0,0,0,.32.614l2.5,1.749A.75.75,0,0,0,10,15.249V7.061l5.78-5.78A.751.751,0,0,0,15.249,0Z" transform="translate(0)" fill="#EF7D00" />
              </svg>
              {language === "en_US" ? "Filter" : "Filtrar"}
            </button>

            {(!!filterTime || !!filterRoom || !!filterTheme) && (
              <button onClick={clearFilters}><span>x</span> {language === "en_US" ? "clean filter" : "Limpar filtro"}</button>
            )}
          </div>
          <Form
            ref={formRef}
            className={`form-filters ${toggle ? 'show' : 'hidden'}`}
            onSubmit={handleSubmitFiltres}
          >
            <button onClick={() => setToggle(!toggle)} className="close-form" type="button">
              {language === "en_US" ? "Close X" : "Fechar X"}
            </button>

            <InputSelectDinamic
              data={[
                { id: "1", name: language === "pt_BR" ? "Plenárias" : "Plenary", value: "Plenárias | Plenary" },
                { id: "2", name: language === "pt_BR" ? "Sala 1" : "Room 1", value: "Sala 1 | Room 1" },
                { id: "3", name: language === "pt_BR" ? "Sala 2" : "Room 2", value: "Sala 2 | Room 2" },
                { id: "4", name: language === "pt_BR" ? "Sala 3" : "Room 3", value: "Sala 3 | Room 3" },
                { id: "5", name: language === "pt_BR" ? "Sala 4" : "Room 4", value: "Sala 4 | Room 4" },
                { id: "6", name: language === "pt_BR" ? "Sala 5" : "Room 5", value: "Sala 5 | Room 5" },
                { id: "7", name: language === "pt_BR" ? "Sala 6" : "Room 6", value: "Sala 6 | Room 6" },
                { id: "8", name: language === "pt_BR" ? "Sala 7" : "Room 7", value: "Sala 7 | Room 7" },
                { id: "9", name: language === "pt_BR" ? "Box 1" : "Box 1", value: "Box 1 | Box 1" },
                { id: "10", name: language === "pt_BR" ? "Box 2" : "Box 2", value: "Box 2 | Box 2" },
                { id: "11", name: language === "pt_BR" ? "Box 3" : "Box 3", value: "Box 3 | Box 3" },


              ]}
              name='room'
              optionType={language === "en_US" ? "Room" : "Sala"}
            />
            <InputSelectDinamic
              data={
                themeSelects.map(theme => {
                  if (language === "pt_BR") {
                    return { id: theme.id, name: theme.name?.split(" | ")[0], value: theme.name }
                  }
                  return { id: theme.id, name: theme.name?.split(" | ")[1], value: theme.name }
                })
              }
              name='theme'
              optionType={language === "en_US" ? "Theme" : "Tema"}
            />
            <InputSelectDinamic
              data={[
                { id: "1", name: language === "pt_BR" ? "Manhã" : "Morning", value: "Manhã" },
                { id: "2", name: language === "pt_BR" ? "Tarde" : "Evening", value: "Tarde" }
              ]}
              name='time'
              optionType={language === "en_US" ? "Period" : "Período"}
            />
            <button type="submit" disabled={!data.length > 0}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path d="M15.249,0H.751a.751.751,0,0,0-.53,1.28L6,7.061V13.5a.75.75,0,0,0,.32.614l2.5,1.749A.75.75,0,0,0,10,15.249V7.061l5.78-5.78A.751.751,0,0,0,15.249,0Z" transform="translate(0)" fill="#EF7D00" />
              </svg>
              {language === "en_US" ? "Filter" : "Filtrar"}
            </button>
          </Form>
        </header>
        <div className="content">
          <div className="content__dates">
            <ul>
              {data.map(dataShedule => {
                if (dataShedule.posts.length > 0) {
                  if (language === "pt_BR") {
                    return (
                      <li
                        key={dataShedule.id}
                        className={dataShedule.date === dateShedule ? 'active' : null}
                        onClick={() => setDateShedule(dataShedule.date)}
                      >
                        <span>{dataShedule.dateFormatted.weekday}</span>
                        <span>{dataShedule.dateFormatted.day}</span>
                        <span>{dataShedule.dateFormatted.month}, {dataShedule.dateFormatted.year}</span>
                      </li>
                    )
                  } else {
                    return (
                      <li
                        key={dataShedule.id}
                        className={dataShedule.date === dateShedule ? 'active' : null}
                        onClick={() => setDateShedule(dataShedule.date)}
                      >
                        <span>{dataShedule.dateFormattedEN.weekday}</span>
                        <span>{dataShedule.dateFormattedEN.day}</span>
                        <span>{dataShedule.dateFormattedEN.month}, {dataShedule.dateFormattedEN.year}</span>
                      </li>
                    )
                  }
                }

                return null
              })}
            </ul>
          </div>
          <div className="content__shedules">
            {currentShedules
              .filter(handleFilterRoom)
              .filter(handleFilterTheme)
              .filter(handleFilterTime)
              .filter(shedule => shedule.translations === language)
              .sort(ordenSchedule)
              .map((shedule) => {
                // return renderShedule(shedule)
                return(
                  <div key={shedule.id} className={`shedule ${shedule.spotlight ? 'shedule--spotlight' : ''}`}>

                  <div className="shedule__date">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="15.5" viewBox="0 0 15.5 15.5">
                      <path d="M8,.25A7.75,7.75,0,1,0,15.75,8,7.749,7.749,0,0,0,8,.25ZM9.784,11.191l-2.756-2a.377.377,0,0,1-.153-.3V3.625A.376.376,0,0,1,7.25,3.25h1.5a.376.376,0,0,1,.375.375v4.3l1.984,1.444a.375.375,0,0,1,.081.525l-.881,1.212a.378.378,0,0,1-.525.081Z" transform="translate(-0.25 -0.25)" fill="#6f8080" />
                    </svg>
                    <p>
                      <span>{shedule.start?.replace(":", "h")}</span>
                      <span>{shedule.end?.replace(":", "h")}</span>
                    </p>
                  </div>
                  <div className="shedule__content">
                    {shedule.group ? (
                      <Fragment>
                        {shedule.group.map(sheduleItem => (
                          sheduleItem?.room?.name?.toLowerCase().includes(filterRoom.toLowerCase()) ?
                          <div key={sheduleItem.id}>       
                            <h2>
                              {language === "pt_BR"
                                ? sheduleItem.theme?.name?.split(" | ")[0]
                                : sheduleItem.theme?.name?.split(" | ")[1]
                              }{" "}
                              <strong>{ sheduleItem.room?.name != 'vazio' &&
                                language === "pt_BR"
                                  ? sheduleItem.room?.name?.split(" | ")[0]
                                  : sheduleItem.room?.name?.split(" | ")[1]}
                              </strong>
                            </h2>
                            {sheduleItem.initials && <p><span>{sheduleItem.initials} -</span> {sheduleItem.title}</p>}
                            {console.log(sheduleItem.spotlight)}
                           {!sheduleItem.spotlight && (
                              <button onClick={() => handleModal(sheduleItem.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16">
                                  <path d="M5.5,11a3,3,0,0,0,3-3V3a3,3,0,0,0-6,0V8A3,3,0,0,0,5.5,11Zm5-5H10a.5.5,0,0,0-.5.5V8a4,4,0,0,1-4.4,3.981A4.121,4.121,0,0,1,1.5,7.822V6.5A.5.5,0,0,0,1,6H.5a.5.5,0,0,0-.5.5V7.755a5.672,5.672,0,0,0,4.75,5.678V14.5H3a.5.5,0,0,0-.5.5v.5A.5.5,0,0,0,3,16H8a.5.5,0,0,0,.5-.5V15a.5.5,0,0,0-.5-.5H6.25V13.445A5.505,5.505,0,0,0,11,8V6.5A.5.5,0,0,0,10.5,6Z" fill="#6f8080" />
                                </svg>
                                <span>{language === "en_US" ? "read more" : "Saiba mais"}</span>
                              </button>
                            )} 
                          </div>
                          :
                          null
                        ))}
                      </Fragment>
                    ) : (
                      <div>
                        <h2>
                          {language === "pt_BR"
                            ? shedule.theme?.name?.split(" | ")[0]
                            : shedule.theme?.name?.split(" | ")[1]
                          }{" "}
                          <strong>{shedule.room?.name != 'vazio' &&
                            language === "pt_BR"
                              ? shedule.room?.name?.split(" | ")[0]
                              : shedule.room?.name?.split(" | ")[1]}
                          </strong>
                        </h2>
                        {shedule.initials && <p><span>{shedule.initials} -</span> {shedule.title}</p>}
                        {!shedule.spotlight && (
                          <button onClick={() => handleModal(shedule.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16">
                              <path d="M5.5,11a3,3,0,0,0,3-3V3a3,3,0,0,0-6,0V8A3,3,0,0,0,5.5,11Zm5-5H10a.5.5,0,0,0-.5.5V8a4,4,0,0,1-4.4,3.981A4.121,4.121,0,0,1,1.5,7.822V6.5A.5.5,0,0,0,1,6H.5a.5.5,0,0,0-.5.5V7.755a5.672,5.672,0,0,0,4.75,5.678V14.5H3a.5.5,0,0,0-.5.5v.5A.5.5,0,0,0,3,16H8a.5.5,0,0,0,.5-.5V15a.5.5,0,0,0-.5-.5H6.25V13.445A5.505,5.505,0,0,0,11,8V6.5A.5.5,0,0,0,10.5,6Z" fill="#6f8080" />
                            </svg>
                            <span>{language === "en_US" ? "read more" : "Saiba mais"}</span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                )
              })}
          </div>
        </div>
      </Container>

      <Modal className={`${toggleModal ? 'show' : 'hidden'}`}>
        <div className="modal-shedule">
          <div className="modal-shedule__header">
            {language === "en_US" && (
              <p>{sheduleSelect?.dateLectureFormattedEN?.weekday} {sheduleSelect?.dateLectureFormattedEN?.month} <strong>{sheduleSelect?.dateLectureFormattedEN?.day}</strong>, {sheduleSelect?.dateLectureFormattedEN?.year}</p>
            )}
            {language === "pt_BR" && (
              <p>{sheduleSelect?.dateLectureFormatted?.weekday} <strong>{sheduleSelect?.dateLectureFormatted?.day}</strong> {sheduleSelect?.dateLectureFormatted?.month}, {sheduleSelect?.dateLectureFormatted?.year}</p>
            )}
            <button onClick={() => setToggleModal(false)}>
              {language === "en_US" ? "Close X" : "Fechar X"}
            </button>
          </div>
          <ModalSheduleContent>
          <div className="modal-shedule__content">
            <h2>
              {language === "pt_BR"
                ? sheduleSelect.theme?.name?.split(" | ")[0]
                : sheduleSelect.theme?.name?.split(" | ")[1]
              }{" "}
              <strong>
                {language === "pt_BR"
                  ? sheduleSelect?.room?.name?.split(" | ")[0]
                  : sheduleSelect?.room?.name?.split(" | ")[1]
                }
              </strong>
            </h2>
            {sheduleSelect?.initials && <p><span>{sheduleSelect?.initials} -</span> {sheduleSelect?.title}</p>}
            <p>{ReactHtmlParser(sheduleSelect?.excerpt)}</p>
          </div>
          </ModalSheduleContent>
          {sheduleSelect?.speakers?.length > 0 && (
            <div className="modal-shedule__list-container">
              <h2>
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16">
                  <path d="M5.5,11a3,3,0,0,0,3-3V3a3,3,0,0,0-6,0V8A3,3,0,0,0,5.5,11Zm5-5H10a.5.5,0,0,0-.5.5V8a4,4,0,0,1-4.4,3.981A4.121,4.121,0,0,1,1.5,7.822V6.5A.5.5,0,0,0,1,6H.5a.5.5,0,0,0-.5.5V7.755a5.672,5.672,0,0,0,4.75,5.678V14.5H3a.5.5,0,0,0-.5.5v.5A.5.5,0,0,0,3,16H8a.5.5,0,0,0,.5-.5V15a.5.5,0,0,0-.5-.5H6.25V13.445A5.505,5.505,0,0,0,11,8V6.5A.5.5,0,0,0,10.5,6Z" fill="#6f8080" />
                </svg>
                {language === "en_US" ? "Speakers" : "Palestrantes"}
              </h2>

              <table>
                <thead>
                  <tr>
                    <th>{language === "en_US" ? "Member" : "Membro"}</th>
                    <th>{language === "en_US" ? "Company" : "Empresa"}</th>
                  </tr>
                </thead>
                <tbody>
                  {sheduleSelect?.speakers.map(speaker => (
                    <tr key={speaker.id}>
                      <td>{speaker.name}</td>
                      <td>{speaker.company}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="modal-shedule__footer">
            <SocialShare slug="programacao" page={true} />
            {/* <a
              className="button-subscribe"
              href="https://icongresso.ibp.itarget.com.br/estacao/index/login/evento/5"
              target="_blank"
              rel="noopener noreferrer"
            >
              {language === "en_US" ? "Sign up" : "Inscreva-se"}
            </a> */}
          </div>
        </div>
      </Modal>

      <Container>
        {/* <Link
          className="button-subscribe"
          to={language === 'en_US'
            ? '/sign-up'
            : '/inscreva-se'}
        >
          {language === "en_US" ? "Sign up" : "Inscreva-se"}
        </Link> */}
      </Container>
    </Fragment>
  )
}



export default Shedule
