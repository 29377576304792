import React from "react";
import { ContainerPlayer } from "./styles";
import { useLanguage } from "../../hooks/useLanguage";

const Player = ({ children }) => {
  const { language } = useLanguage();
  return (
    <ContainerPlayer>
      {children}
      <div className="welcome__video">
        <iframe
          src="https://www.youtube.com/embed/j5PJp7gbkII?si=E3Q7UM6etRNnKqNa"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="welcome__content">
        <h2 className="subtitle">
          {language === "en_US"
            ? "Rio Pipeline 2025 will be the biggest yet!"
            : "A Rio Pipeline 2025 será ainda o maior!"}
        </h2>
        {language === "en_US" ? (
          <p>
            We will once again bring together our community and internationally renowned experts at the sector’s main meeting point! One of the major highlights of this edition will be the addition of a dedicated agenda for the area of <strong>logistics.</strong> The program will feature <strong>highly relevant technical content,</strong> with in-depth lectures and panels on technological innovations and sustainability. Additionally, we will have an <strong>expanded space,</strong> creating opportunities for <strong>logistics sector exhibitors</strong> to present their solutions and connect with key industry players. Get ready for an expanded edition, with new topics and more space for connections and business in the sector. See you there!
          </p>
        ) : (
          <p>
            Reuniremos mais uma vez nossa comunidade e especialistas de renome internacional no principal ponto de encontro do setor! Uma das grandes novidades desta edição será a aplicação do temário para a área de <strong>logística.</strong> A programação contará com <strong>conteúdo técnico de alta relevância,</strong> com palestras e painéis aprofundados sobre inovações tecnológicas e sustentabilidade. Além disso, teremos uma <strong>ampliação do espaço,</strong> criando oportunidades para <strong>expositores do setor de logística</strong> apresentarem suas soluções e se conectarem com os principais players da indústria. Prepare-se para uma edição ampliada, com novos temas e mais espaço para conexões e negócios no setor. Nos vemos lá!

            
          </p>
        )}
      </div>
    </ContainerPlayer>
  );
};

export default Player;
