import styled from 'styled-components'

export const GridContainer = styled.div`
  .grid-container {
    display: grid;
    background-color: #6F8080;
    padding: 80px 0 40px;

    &__content {
      width: 100%;
      max-width: 1366px;
      margin: 0 auto;
      padding: 0 2rem;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 120px;

      .content-text {
        max-width: 635px;

        h2 {
          font-family: 'Open Sans', sans-serif;
          font-size: 2.25rem;
          font-weight: 700;
          color: #fff;
          margin-bottom: 2rem;
        }

        .wrapper-content {
          div {
            display: grid;
            grid-template-columns: 40px 1fr;
            column-gap: 20px;
            align-items: center;

            & + div {
              margin-top: 30px;
            }

            svg {
              width: auto;
              height: auto;
              max-width: 40px;
            }

            p {
              font-family: 'Open Sans', sans-serif;
              font-size: 1.1704rem;
              font-weight: 300;
              color: #fff;
              margin: 0;
            }
          }          
        }
      }

      .content-form {
        max-width: 100%;

        .contact {
          width: 100%;
          max-width: 535px;
          padding: 35px 30px;
          background-color: #000000;
          border-radius: 8px;

          h3 {
            font-family: 'Open Sans', sans-serif;
            font-size: 2.25rem;
            color: #fff;
            margin: 0;
            margin-bottom: 1rem;
          }

          label {
            font-size: 1.1704rem;
            display: flex;
            flex-direction: column;
            color: #fff;
            margin-bottom: 1rem;

            &.check {
              flex-direction: row;
              align-items: center;

              span {
                margin: 0;
              }
            }

            span.require {
              color: tomato;
              font-size: 14px;
              padding-top: 2px;
            }

            input {
              width: 100%;
              max-width: 100%;
              height: 45px;
              padding-left: 15px;

              &[type="checkbox"] {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }

              &.message {
                height: 115px;
              }
            }

            textarea {
              width: 100%;
              max-width: 100%;
              height: 115px;
              padding-left: 10px;
              padding-top: 8px;
            }
          }
        }

        button {
          border: none;
          font-size: 0.875rem;
          font-weight: 600;
          color: #000000;
          padding: 12px 45px;
          text-transform: uppercase;
          margin-top: 1rem;
          background-color: var(--orange);
        }
        button:hover {
          background-color: transparent;
          border: 1px solid  #EF7D00; 
          color: #EF7D00;
       }
      } 
    }
  }

  @media screen and (max-width: 990px) {
    .grid-container {
      display: grid;
      background-color: #6F8080;
      padding: 45px 0;

      &__content {
        grid-template-columns: 1fr;
        row-gap: 45px;

        .content-text {
          margin: 0 auto;

          h3 {
            font-size: 2.0064rem;
          }

          h2 {
            font-size: 2.0064rem;
          }
          .wrapper-content {
            p {
              font-size: 1.1704rem;
            }
          }
        }

        .content-form {
          .contact {
            background-color: transparent;
            padding: 35px 0;
            margin: 0 auto;
          }
          button{
            background-color: var(--orange);
            color: #000000;
          }
          button:hover {
            pointer-events: none;
            background: orange;
            color: black;
          }
        }
      }
    }
  }
`





