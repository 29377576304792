import styled from 'styled-components';
// import { breakpoints } from '../utils/breackpoints'


export const Container = styled.section` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  
  button[type=submit] {
     flex: 1 1 auto;
     width: 150px;
     height: 45px;
   

     border: 0;
     background-color: var(--orange);
     color: #000000;

     font-family: 'Open Sans', sans-serif;
     font-size: 1.0032rem;
     font-weight: 700;
     text-transform: uppercase;

     transition: filter .3s;

     &:hover {
       filter: brightness(.8);
       background-color: transparent;
       border: 1px solid  #EF7D00; 
       color: #EF7D00;
     }
   }

`
export const Top = styled.div` 
  position: relative;
  min-height: 200px;
  width: 100%;
  background-image: url(${props => props.BgImage});
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  h1 {
    color: #fff;
    font-size: 2.25rem;
    font-weight: 700;
  }


`
export const Content = styled.div` 
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 980px;
  padding: 0 40px;
  margin-top: 40px;

  h2 {
    display: inline-block;
    font-family: "Open Sans",sans-serif;
    font-size: 1.875rem;
    line-height: 2.1875rem;
    font-weight: 700;
    text-align: left;
    margin-bottom: 1rem;
    color: var(--orange);

    &.main-title::after {
      content: '';
      display: block;
      width: 70%;
      height: 1px;
      background-color: #000;
      margin-top: 10px;
    }
  }

  h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.75rem;
    font-weight: 800;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 300;
  }

  ul.accordion {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-top: 15px;
      overflow: hidden;
      transition: height .3s ease;

      h3 {
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #747474;
        padding-bottom: 15px;
        margin-bottom: 25px;

        svg {
          transform: rotate(180deg);
        }
      }

      div {
        p:last-of-type {
          margin-bottom: 0;
        }

        p {
          strong {
            font-weight: 700;
          }
        }
      }

      &.active {
        h3 {
          svg {
            transform: rotate(0);
          }
        }
      }
    }
  }

  .subscription {
    margin-bottom: 90px;

    h2 {
      text-align: left;
      margin-bottom: 0;
      margin-bottom: 20px;
    }

    & > p {
      font-family: 'Open Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 300;

      a, span {
        cursor: pointer;
        font-weight: 600;
        color: #EF7D00;
      }
    }

    label {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      &.input-checkbox {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        column-gap: 10px;

        p {
          margin: 0;
          grid-column-start: 2;
          grid-column-end: 3;

          span {
            cursor: pointer;
            font-weight: 600;
            color: #EF7D00;
          }
        }

        input[type="checkbox"] {
          grid-row-start: 1;
          grid-column-start: 1;
          grid-column-end: 2;
        }

        .require {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }

      p {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.875rem;
        margin: 0;
        margin-bottom: 5px;
      }

      input {
        height: 35px;
        padding: 0 5px;
        border: 1px #DCDCDC solid;

        &[type="file"] {
          opacity: 0;
          display: none;
        }
      }

      textarea {
        height: 120px;
        border: 1px #DCDCDC solid;
        padding: 0 5px;
      }

      .customInput {
        display: flex;
        align-items: center;
        width: max-content;
        height: 40px;
        padding: 15px;
        background-color: #F1F1F1;
        border: 1px solid #DCDCDC;
        
        font-family: 'Open Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 600;
        transition: all .3s ease;
        
        svg {
          margin-left: 15px;
        }

        &:hover {
          background-color: #D8D8D8;
        }
      }

      .require {
        color: tomato;
        font-size: 0.75rem;
      }
    }

    button[type="submit"] {
      font-family: 'Open Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 600;
      margin-top: 15px;
    }
  }
`
export const Form = styled.form` 
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;

`
export const FromGroup = styled.div`  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    margin: 0 auto;
    span {
      color: red;
    }
     a {
      text-decoration: underline;
      color: var(--orange);
      font-weight: bold;
    }
    p {
      font-size: 0.9rem;
    }
    label {
        font-size: 1rem; 
        color: var(--black);
        &:not(last-child) {
            margin-bottom: 0.313rem;    
        }
        
    }
    &:not(last-child) {
        margin-bottom: 1.5rem;
    }
    .msg {
      height: 168px;
    }
`

export const Input = styled.input`
  height:45px;
  
  border: 1px solid var(--gray-1);
  &:focus{
      outline:none;
  }
  box-sizing: border-box;
  padding-left: 10px;


`;
export const InputFileIcon = styled.div` 
    display:flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    max-width: 30%;
    max-height:25px;
    border: 1px solid #999;
    padding: 2rem;

    input[type="file"] {
      display: none;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
` ;

export const ConteinerCheckbox = styled.div`
display: flex;
flex-direction: row;

p {
  margin-left: 1rem;
  font-size: 0.8rem;
  margin: 0;
  padding: 12px;
}
  .checkbox{
    height:45px;
    width: 20px;
  }

  

`

