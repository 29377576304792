import React, { useState } from 'react'
import { LinkedinShareButton, FacebookShareButton, WhatsappShareButton } from 'react-share'
import { useLanguage } from '../../hooks/useLanguage'
import { Container } from './styles'

export default function SocialShare({ slug, inverte = false, page = false }) {
  const { language } = useLanguage()
  const [toggle, setToggle] = useState(false)

  return (
    <Container>
      {page && <p>
        {language === "en_US" ? "Share" : "Compartilhe"}
      </p>}

      <div className={`social-media-share ${inverte ? 'social-media-share--invert' : ''}`}>
        <button
          className={`mobile-share ${inverte ? 'mobile-share--invert' : ''}`}
          onClick={() => setToggle(!toggle)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18.2" height="20" viewBox="0 0 18.2 20"><g transform="translate(-3.5 -2)">
            <path d="M27.9,5.7A2.7,2.7,0,1,1,25.2,3,2.7,2.7,0,0,1,27.9,5.7Z" transform="translate(-7.2)" fill="none" stroke="#EF7D00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /><path d="M9.9,16.2a2.7,2.7,0,1,1-2.7-2.7A2.7,2.7,0,0,1,9.9,16.2Z" transform="translate(0 -4.2)" fill="none" stroke="#EF7D00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /><path d="M27.9,26.7A2.7,2.7,0,1,1,25.2,24,2.7,2.7,0,0,1,27.9,26.7Z" transform="translate(-7.2 -8.4)" fill="none" stroke="#EF7D00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /><path d="M12.885,20.265l6.147,3.582" transform="translate(-3.354 -6.906)" fill="none" stroke="#EF7D00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /><path d="M19.023,9.765l-6.138,3.582" transform="translate(-3.354 -2.706)" fill="none" stroke="#EF7D00" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /></g>
          </svg>
        </button>
        <div className={`shares ${toggle ? 'show' : ''}`}>
          <WhatsappShareButton url={`${window.location.origin}/${slug}`}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                <path d="M14.454,2.47A8.427,8.427,0,0,0,1.2,12.636L0,17l4.466-1.173a8.4,8.4,0,0,0,4.026,1.025h0A8.505,8.505,0,0,0,17,8.428,8.457,8.457,0,0,0,14.454,2.47ZM8.5,15.433a6.989,6.989,0,0,1-3.567-.975l-.254-.152L2.026,15l.706-2.584-.167-.266A7.015,7.015,0,1,1,15.577,8.428a7.08,7.08,0,0,1-7.081,7Zm3.84-5.244c-.209-.106-1.245-.615-1.438-.683s-.334-.106-.474.106-.543.683-.668.827-.247.159-.455.053a5.73,5.73,0,0,1-2.865-2.5c-.216-.372.216-.345.619-1.15a.39.39,0,0,0-.019-.368c-.053-.106-.474-1.142-.649-1.563s-.345-.353-.474-.36-.262-.008-.4-.008a.78.78,0,0,0-.562.262,2.365,2.365,0,0,0-.736,1.757A4.124,4.124,0,0,0,5.07,8.735a9.406,9.406,0,0,0,3.6,3.18,4.119,4.119,0,0,0,2.527.527,2.156,2.156,0,0,0,1.419-1,1.76,1.76,0,0,0,.121-1C12.686,10.344,12.545,10.291,12.337,10.189Z" transform="translate(17) rotate(90)" fill="#EF7D00" />
              </svg>
            </span>
          </WhatsappShareButton>
          <FacebookShareButton url={`${window.location.origin}/${slug}`}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9.105" viewBox="0 0 17 9.105">
                <path d="M8.508,9.563l.472-3.077H6.028v-2A1.538,1.538,0,0,1,7.763,2.827H9.105V.208A16.366,16.366,0,0,0,6.723,0C4.292,0,2.7,1.474,2.7,4.141V6.486H0V9.563H2.7V17H6.028V9.563Z" transform="translate(17) rotate(90)" fill="#EF7D00" />
              </svg>
            </span>
          </FacebookShareButton>
          <LinkedinShareButton url={`${window.location.origin}/${slug}`}>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="15.867" height="15.867" viewBox="0 0 15.867 15.867">
                <path d="M3.552,15.867H.262V5.273h3.29ZM1.905,3.828A1.914,1.914,0,1,1,3.81,1.905,1.921,1.921,0,0,1,1.905,3.828ZM15.863,15.867H12.581V10.71c0-1.229-.025-2.805-1.71-2.805C9.16,7.9,8.9,9.24,8.9,10.621v5.245H5.612V5.273H8.767V6.718h.046a3.457,3.457,0,0,1,3.112-1.711c3.329,0,3.941,2.192,3.941,5.04v5.819Z" transform="translate(15.867) rotate(90)" fill="#EF7D00" />
              </svg>
            </span>
          </LinkedinShareButton>
        </div>
      </div>
    </Container>
  )
}