import styled from 'styled-components';

export const ContainerForm = styled.section`
  max-width: 475px;
  margin: 40px 0;
  font-family: 'Open Sans', sans-serif;

  .menu-bars {
    display: block;
    text-align: end;
    color: orange;
    text-decoration: underline;
  }

  .contact input[type="text"],
  .contact input[type="email"],
  .contact input[type="tel"],
  .contact input[type="name"],
  .contact input[type="message"],
  .contact textarea,
  .contact button[type="submit"] {
    width: 100%;
    max-width: 100%;
    height: 45px;
    padding-left: 15px;
  }

.contact__header {
  max-width: 100%;
}

  .contact span.title {
    display: block;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
  }
  .contact-form .contact{
   width: 100%;
   max-width: 535px;
   padding: 35px 30px;
   background-color: #000000;
   color: #fff;
   border-radius: 8px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    
    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    color: var(--orange);
    text-decoration: underline;
  }


.contact span{
  border: medium none !important;
  min-width: 100%;
  padding: 0;
  width: 100%;
  color: red;
}
.content-select span{
  color: #FFF;
}
label {
  border: medium none !important;
  min-width: 100%;
  padding: 0;
  width: 100%;
  color: #FFF;
  margin-bottom: 10px;

  p {
    margin-bottom: 5px;
  }
}

label  .select {
  border: medium none !important;
  min-width: 100%;
  padding: 0;
  width: 100%;
  color: #FFF;
}

.contact input[type="text"],
.contact input[type="email"],
.contact select,
.contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  color: #000000;
  margin: 0 0 5px;
  padding: 10px;
}

.contact .custom-select {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  outline: none;
}

.contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

.contact button[type="submit"] {
  cursor: pointer;
  width: 9.313rem;
  height: 2.875rem;
  border: none;
  background: #EF7D00;
  color: #000000;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;

}

.contact button[type="submit"]:hover {
  background: #EF7D00;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
  text-align: center;
}


`;


