import React, { Fragment } from "react";

import { Container, Stack } from "./styles";

import Sheet from "../../assests/v1/icons/sheet.png";
import Idea from "../../assests/v1/icons/idea.png";
import Point from "../../assests/v1/icons/point.png";
import Calendar from "../../assests/v1/icons/calendar.png";
import { useLanguage } from "../../hooks/useLanguage";
import { Link } from 'react-router-dom'

const EventInfo = (papers) => {
  const { language } = useLanguage();
  return (
    <Container id="next-section">
      <h1>{language === "en_US" ? `Timeline` : `Cronograma`}</h1>
      <div className="content">
          <p className="text">
            <strong>{language === "en_US" ? `Phase 1:` : `Fase 1:`}</strong>
          </p>
          <p>
            {language === "en_US" ? `Receipt of ABSTRACTS: from 11/04 to 01/24` : `Recebimento dos RESUMOS: de 04/11 até 24/01 `} <br></br>
            {language === "en_US" ? `Evaluations and announcement of results: from 01/27 to 02/28` : `Avaliações e anúncio do resultado: de 27/01 até 28/02  `}
          </p>

          <p className="text">
            <strong>{language === "en_US" ? `Phase 2:` : `Fase 2:`}</strong>
          </p>
          <p>
            {language === "en_US" ? `Receipt of COMPLETE PAPER: from 03/13 to 04/25` : `Recebimento do TRABALHO COMPLETO: de 13/03 até 25/04`} <br></br>
            {language === "en_US" ? `Evaluations and announcement of results: from 04/28 to 06/10` : `Avaliações e anúncio do resultado: 28/04 até 10/06 `}
          </p>

          <p className="text">
            <strong>{language === "en_US" ? `Phase 3:` : `Fase 3:`}</strong>
          </p>
          <p>
            {language === "en_US" ? `Receipt of corrected article: from 06/16 to 06/27` : `Recebimento do artigo corrigido: de 16/06 até 27/06`} <br></br>
            {language === "en_US" ? `Evaluations and announcement of final results: from 06/30 to 07/16` : `Avaliações e anúncio do resultado final: de 30/06 até 16/07`}
          </p>
      </div>

    </Container>
  );
};

export default EventInfo;
