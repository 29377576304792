import styled from "styled-components"

export const Container = styled.section`
  text-align: center;
  height: auto;
  width: 100%;
  filter: none;

  button {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    
    margin-top: 2rem;
    width: 272px;
    height: 46px;
    background-color: var(--orange);
    border: 2px orange solid;
    color: black;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    font-weight: 700;
    transition: .5s;
    overflow: hidden;
  }

  button:hover {
    background-color: transparent;
    border-color: #EF7D00; 
    color: #EF7D00;
  }

  .container_parallax {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    min-height: 355px;
    background-color: rgba(0,0,0,0.7);
  }  


`
export const Content = styled.section`
  position: absolute;
  width: 1366px;
  max-width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  transform: translate(-50, -50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: large;
  color: #fff;
  font-weight: 100;

  h2.content-text {
    font-size: 2.758rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
  }

  span {
    margin-top:  2rem;
    font-size: 1.1704rem;
    font-family: 'Open Sans', sans-serif;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 2;
  }

  .content-text {
    max-width: 710px;
  } 


  @media (max-width: 990px) {
    h2.content-text {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: 1.5rem;
    }

    span {
      display: block;
      margin: 1rem 0 2rem;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 1.1704rem;
    }    

    a {
      button {
        margin-top: 0;
      }
    }
  } 
`