import styled from 'styled-components';

export const CTAAutor = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;

  .btn--outline {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: capitalize;
    color: #FFF;
    padding: 10px 25px;
    background-color: #EF7D00;
    width: fit-content;
    transition: color .2s ease;

    &:hover {
      background-color: #60605F;
      color: #FFF;
      
    }
  }


`;


