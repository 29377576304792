import styled, { keyframes } from "styled-components";

const showElement = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`

const hiddenElement = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`

export const Container = styled.div`
  width: 1366px;
  max-width: 100%;
  margin: 0 auto 60px;
  padding: 0 2rem;

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;

    h1 {
      font-family: 'Open Sans', sans-serif;
      font-size: 2.25rem;
      font-weight: 700;
      color: #000;
      margin: 0;
      line-height: 1;
    }

    .filter-mobile {
      display: none;

      button {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.875rem;
        line-height: 1;

        min-width: 72px;
        height: 25px;
        border: 1px solid #EF7D00;
        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        &:nth-of-type(2) {
          border: none;
          color: #6F8080;
          margin-left: 15px;

          span {
            font-weight: 700;
            margin-right: 5px;
            line-height: 1;
          }
        }

        &:disabled {
          opacity: 0.5;
        }

        svg {
          margin-right: 5px;
        }
      }
    }

    .form-filters {
      display: flex;

      .close-form {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 700;
        color: #6F8080;
        margin-bottom: 1rem;
        
        background: none;
        border: none;

        width: auto;
        display: none;
        margin-left: auto;
      }

      select {
        height: 45px;
        margin-right: 20px;
        font-family: 'Open Sans', sans-serif;
        font-size: 0.875rem;
        padding: 0 10px;
        border: 1px solid #DCDCDC;
        background-color: #fff;

        &:disabled {
          opacity: 0.5;
        }

        option {
          font-family: 'Open Sans', sans-serif;
          font-size: 0.875rem;
        }
      }

      button[type="submit"] {
        font-family: 'Open Sans', sans-serif;
        font-size: 1rem;

        min-width: 90px;
        height: 45px;
        border: 1px solid #EF7D00;
        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;
        
        transition: all .3s ease;

        svg {
          margin-right: 5px;
        }

        &:disabled {
          opacity: 0.5;
        }

        &:hover {
          background-color: #EF7D00;
          color: #000;

          svg {
            path {
              fill: #000;
            }
          }
        }
      }
    }
  }

  .content {
    display: flex;

    &__dates {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          cursor: pointer;
          width: 95px;
          height: 95px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border: 1px solid #D3D3D3;
          color: #6F8080;

          &.active {
            content: #fff;
            background: #EF7D00;
            color: #000;
            border-color: #EF7D00;
          }

          span {
            font-family: 'Open Sans', sans-serif;
            font-size: 0.5625rem;
            line-height: 1;

            &:nth-of-type(2) {
              font-size: 3.25rem;
              font-weight: 700;
            }

            &:nth-of-type(3) {
              font-weight: 700;
            }
          }
        }
      }
    }

    &__shedules {
      flex: 1 1 auto;
      
      .shedule {
        display: grid;
        grid-template-columns: 100px 1fr;
        background-color: #FBFBFB;

        &:hover {
          &.shedule--spotlight {
            .shedule__content {
              div {
                background-color: #A8C4C4;

                h2, p {
                  color: #000;
                }
              }              
            }
          }
        }

        &:nth-of-type(even) {
          background-color: #F6F6F6;
        }

        &--spotlight {
          background-color: #A8C4C4 !important;
        }

        &__date {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          border-right: 1px solid #FFFFFF;
          padding: 15px;

          svg {
            margin-right: 10px;
          }

          & > p {
            display: flex;
            flex-direction: column;
            font-family: 'Open Sans', sans-serif;
            font-size: 0.875rem;
            color: #000000;
            line-height: 1;

            span {
              margin-bottom: 10px;
            }
          }
        }

        &__content {
          display: flex;
          background-color: transparent;
          transition: all .3s ease;

          div {
            flex: 1 1 50%;
            padding: 15px;

            display: flex;
            flex-direction: column;

            &:nth-of-type(2) {
              border-left: 1px solid #D3D3D3;
              padding-left: 25px;
            }

            &:hover {              
              background-color: #000;

              h2, p {
                color: #fff;
              }

              button {
                color: #EF7D00;

                svg {
                  path {
                    fill: #EF7D00;
                  }
                }
              }
            }
          }

          h2 {
            font-family: 'Open Sans', sans-serif;
            font-size: 1.125rem;
            font-weight: 700;
            margin: 0;
            line-height: 1;
            transition: all .3s ease;

            strong {
              display: inline-block;
              font-family: 'Open Sans', sans-serif;
              font-size: 0.875rem;
              font-weight: 500;
              color: #fff;
              background-color: #6F8080;
              padding: 0 5px;
            }
          }

          p {
            flex: 1 1 auto;
            font-family: 'Open Sans', sans-serif;
            font-size: 0.875rem;
            font-weight: 300;
            margin-top: 0.875rem;
            transition: all .3s ease;

            span {
              font-weight: 700;
            }
          }

          button {
            background: none;
            border: none;

            display: flex;
            align-items: center;
            /* margin: 1rem 0; */

            svg {
              margin-right: 15px;
            }

            span {
              text-decoration: underline;
              font-family: 'Open Sans', sans-serif;
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }

  & > a.button-subscribe {
    background-color: #EF7D00;
    border: 1px solid transparent;
    width: 210px;
    height: 45px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;

    transition: all .3s ease;

    &:hover {
      background-color: transparent;
      border-color: #EF7D00;
      color: #EF7D00;
    }
  }

  @media (max-width: 990px) {
    header {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2rem;

      h1 {
        margin-bottom: 1rem;
      }

      .filter-mobile {
        display: flex;
      }

      .form-filters {
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 2rem;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: #fff;

        &.show {
          animation: ${showElement} .3s ease;
          animation-fill-mode: forwards;
        }

        &.hidden {
          animation: ${hiddenElement} .3s ease;
          animation-fill-mode: forwards;
        }

        .close-form {
          display: inherit;
        }

        select {
          width: 100%;
          margin-bottom: 1rem;
        }

        button[type="submit"] {
          width: 90px;
        }
      }
    }

    .content {
      flex-direction: column;

      &__dates {
        ul {
          display: grid;
          grid-template-columns: repeat(auto-fit, 95px);
          column-gap: 30px;

          li {
            margin-bottom: 2rem;
          }
        }
      } 
      
      &__shedules {      
        .shedule {
          &__content {
            display: flex;
            flex-direction: column;

            div {
              flex: 1 1 100%;
              padding: 15px;

              display: flex;
              flex-direction: column;

              &:nth-of-type(2) {
                border-left: none;
                border-top: 1px solid #fff;
                padding-left: 15px;
              }
            }
          }
        }
      }
    }
  }
`

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.9);
  display: flex;

  &.show {
    animation: ${showElement} .3s ease;
    animation-fill-mode: forwards;
  }

  &.hidden {
    animation: ${hiddenElement} .3s ease;
    animation-fill-mode: forwards;
  }

  .modal-shedule {
    background-color: #fff;
    width: 950px;
    max-width: 100%;
    margin: auto auto;

    max-height: 100%;
    overflow: hidden;
    padding: 2rem;
    border-radius: 1rem;

    &__header {
      display: flex;
      justify-content: space-between;

      p {
        background-color: #EF7D00;
        padding: 0 10px;
        font-family: 'Open Sans', sans-serif;
        font-size: 0.875rem;
        color: #000;

        strong {
          font-weight: 1.125rem;
          font-weight: 700;
        }
      }

      button {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 700;
        color: #6F8080;

        background: none;
        border: none;
        text-decoration: underline;
      }
    }

    &__content {
      h2 {
        font-family: 'Open Sans', sans-serif;
        font-size: 1.125rem;
        font-weight: 700;
        margin: 0;
        line-height: 1;
        transition: all .3s ease;

        strong {
          display: inline-block;
          font-family: 'Open Sans', sans-serif;
          font-size: 0.875rem;
          font-weight: 500;
          color: #fff;
          background-color: #6F8080;
          padding: 0 5px;
        }
      }

      p {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 300;
        margin-top: 0.875rem;
        transition: all .3s ease;
        margin-bottom: 1rem;

        span {
          font-weight: 700;
        }
      }
    }

    &__list-container {
      width: 100%;
      border-top: 1px solid #D3D3D3;
      padding-top: 1rem;

      h2 {
        display: flex;
        align-items: center;

        font-family: 'Open Sans', sans-serif;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1;
        color: #EF7D00;

        margin: 0;
        margin-bottom: 0.875rem;

        svg {
          margin-right: 10px;

          path {
            fill: #EF7D00;
          }          
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 2rem;
        display: block;
        max-height: 275px;
        overflow-y: auto;

        thead {
          width: 100%;
          display: block;
          margin-bottom: 10px;

          tr {
            width: 100%;
            display: flex;

            th {
              flex: 1 1 50%;

              font-family: 'Open Sans', sans-serif;
              font-size: 0.875rem;
              font-weight: 700;
            }
          }
        }

        tbody {
          width: 100%;
          display: block;

          tr {
            width: 100%;
            display: flex;
            border-bottom: 1px solid #E9E9E9;

            td {
              flex: 1 1 50%;
              font-family: 'Open Sans', sans-serif;
              font-size: 0.875rem;
              padding: 5px 0;
            }
          }
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a.button-subscribe {
        background-color: #EF7D00;
        border: 1px solid transparent;
        width: 210px;
        height: 45px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: 'Open Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #000;

        transition: all .3s ease;

        &:hover {
          background-color: transparent;
          border-color: #EF7D00;
          color: #EF7D00;
        }
      }
    }
  }

  @media (max-width: 990px) {
    .modal-shedule {
      border-radius: 0;
      margin: 0;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
    }
  }
`

export const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  h2 {
    color: #EF7D00;
    text-transform: uppercase;
    font-weight: bold;
  }

  a {
    color: white;
    background-color: #EF7D00;
    padding: 5px 10px;
    border: 1px solid #EF7D00;
    transition: 0.3s all ease;
    
    &:hover {
      background-color: white;
      color: #EF7D00;
    }
  }
`

export const ModalSheduleContent = styled.div`
max-height: 384px;
overflow-y: scroll;
`