import React, { Fragment } from "react";

import { Container, Stack } from "./styles";

import Sheet from "../../assests/v1/icons/sheet.png";
import Idea from "../../assests/v1/icons/idea.png";
import Point from "../../assests/v1/icons/point.png";
import Calendar from "../../assests/v1/icons/calendar.png";
import { useLanguage } from "../../hooks/useLanguage";
import { Link } from 'react-router-dom'

const EventInfo = (papers) => {
  const { language } = useLanguage();
  return (
    <Container id="next-section">
      <h1>RIO PIPELINE 2025</h1>
      <div className="content">
        <div className="container-icon">
          <img className="icon" src={Point} alt="sheet" />
          <p className="text">
            <strong>{language === "en_US" ? `Venue:` : `Local:`}</strong>{language === "en_US" ? ` EXPO MAG – Rio de Janeiro – Brazil` : ` EXPO MAG – Rio de Janeiro - Brasil`}
          </p>
        </div>

        <div className="container-icon">
          <img className="icon" src={Calendar} alt="sheet" />
          <p className="text">
            <strong>{language === "en_US" ? `Date:` : `Data:`}</strong> {language === "en_US" ? ` september 09 to 11, 2025 ` : ` 09 a 11 de setembro de 2025`}
          </p>
        </div>

        <div className="container-icon">
          <img className="icon" src={Idea} alt="sheet" />
          <p className="text">
            <strong>{language === "en_US" ? `Event theme:` : `Lema do evento:`}</strong>{language === "en_US" ? ` coming soon ` : ` em breve`}
          </p>
        </div>

       {/* @todo: vai ser usado em outro momento */}
        {/* <div className="container-icon">
          <img className="icon" src={Sheet} alt="sheet" />
          <p className="text">
            <strong>{language === "en_US" ? `Exhibition hours:` : `Horário de funcionamento da exposição:`}</strong>{language === "en_US" ? ` 12 to 20pm.` : ` 12 às 20h.`} 
          </p>
        </div> */}

        {/* <Stack>
          // @todo: vai ser usado em breve
          {papers.papers === true ? '' : 
            (<Fragment>
              <div className="container-button">
                <Link to="/call-for-papers">{language === "en_US" ? `PRESENTATION TOPICS` : `CONHEÇA O TEMÁRIO`}</Link>
              </div>
            </Fragment>)
          }
          
          
          <div className="container-button">
            <a href="https://eventos.tmp.br/riopipeline2023/visitors/inicio.php">{language === "en_US" ? `Buy your ticket today` : `Garanta seu ingresso`}</a>
          </div>
        </Stack> */}

      <h2>Call for Papers</h2>

        <div className="container-icon">
          <p className="text">
            <strong>{language === "en_US" ? `Receipt of abstracts:` : `Recebimento dos resumos: `}</strong>{language === "en_US" ? ` from November 4, 2024 to January 24, 2025.` : ` de 04 de novembro de 2024 a 24 de janeiro de 2025.`}
          </p>
        </div>
      </div>

    </Container>
  );
};

export default EventInfo;
