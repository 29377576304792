import styled from "styled-components"

export const MainContainer = styled.div`
    max-width: 80%;
    margin: 50px auto 0;

    @media screen and (max-width: 720px) {
        max-width: 90%;
    }
`

export const UpperContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #EEEEEE;

    @media screen and (max-width: 720px) {
        flex-direction: column;
        gap: 30px;
    }
`

export const LowerContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 2%;

    margin-bottom: 50px;

    @media screen and (max-width: 720px) {
        justify-content: flex-start;
    }
    
    .staff-member {
        max-width: 15%;
        min-width: 15%;
        position: relative;

        @media screen and (max-width: 720px) {
            max-width: 49%;
            width: 49%;
        }

        &__content {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 80px;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);

            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            padding: 0 10px 10px;
            width: 100%;

            svg {
                transition: all 0.3s ease;
                fill: white;

                &:hover {
                    fill: #EF7D00;
                }
            }
        }

        &__text {
            max-width: 86px;

            .name {
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                font-weight: bold;
                color: white;
                margin: 0;

                @media screen and (max-width: 720px) {
                    font-size: 14px;
                }
            }

            .company {
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
                font-weight: normal;
                color: white;
                margin: 0;

                @media screen and (max-width: 720px) {
                    font-size: 12px;
                }
            }
        }

        img {
            max-width: 100%;
        }
    }
`

export const ChairCard = styled.div`
    width: 50%;
    display: flex;
    gap: 20px;

    @media screen and (max-width: 720px) {
        width: 100%;
    }

    .staff-member__content {
        .badge {
            text-transform: uppercase;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: black;
            margin-bottom: 10px;
            padding: 0;
        }

        .name {
            font-size: 24px;
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            color: black;
            margin: 0;
            line-height: 1;
        }
        
        .company {
            font-size: 18px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: black;
            line-height: 1;
        }

        svg {
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                fill: #EF7D00;
            }
        }
    }

    .image-group {
        position: relative;

        img {
            max-height: 360px;

            @media screen and (max-width: 720px) {
                max-height: 230px;
            }
        }

        .arrow {
            position: absolute;
            display: block;
            left: -32px;
            top: -7px;
            width: 0;
            height: 10px;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            border-bottom: 50px solid #EF7D00;
            transform: rotate(-45deg);
        }
    }
`
export const CoChairdCard = styled.div`
    width: 50%;
    display: flex;
    gap: 20px;

    @media screen and (max-width: 720px) {
        width: 100%;
    }

    .staff-member__content {
        .badge {
            text-transform: uppercase;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: black;
            margin-bottom: 10px;
            padding: 0;
        }

        .name {
            font-size: 24px;
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            color: black;
            margin: 0;
            line-height: 1;
        }
        
        .company {
            font-size: 18px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            color: black;
            line-height: 1;
        }

        svg {
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                fill: #EF7D00;
            }
        }
    }

    .image-group {
        position: relative;

        img {
            max-height: 360px;

            @media screen and (max-width: 720px) {
                max-height: 230px;
            }
        }

        .arrow {
            position: absolute;
            display: block;
            left: -32px;
            top: -7px;
            width: 0;
            height: 10px;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            border-bottom: 50px solid black;
            transform: rotate(-45deg);
        }
    }
`